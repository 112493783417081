import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disable-trix-attachments"
// Credit to Donn Felker for this : https://www.donnfelker.com/disable-attachments-in-the-trix-editor/
export default class extends Controller {
  connect() {
    document.addEventListener("trix-initialize", function() {
      const btn = document.querySelector('span.trix-button-group--file-tools');
      btn?.remove();
    })
    document.addEventListener("trix-attachment-add", function(event) {
      if (!event.attachment.file) event.attachment.remove();
    })
    document.addEventListener("trix-file-accept", function(event) {
      event.preventDefault();
    })
  }
}
