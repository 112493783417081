import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

// Connects to data-controller="convo-subscription"
export default class extends Controller {
  static values = {
    convoId: Number,
    userName: String
  };
  static targets = ["messages"];

  connect() {
    this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight)
    this.channel = createConsumer().subscriptions.create(
      { channel: "ConvoChannel", id: this.convoIdValue },
      { received: data => this.addReceivedMsg(data) }
    );
  }

  disconnect() {
    this.channel.unsubscribe();
  }

  addReceivedMsg(message) {
    let preparedMessage = message;
    if(!message.includes(this.userNameValue)) {
      preparedMessage = message.replace(/my-msg\s/, "");
    }
    this.messagesTarget.insertAdjacentHTML("beforeend", preparedMessage);
    this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight);
  }

  afterSend(e) {
    e.target.reset();
    this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight);
  }
}
