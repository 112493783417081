import { Controller } from "@hotwired/stimulus"
// potentially not being used anywhere in the project. Consider deleting

// Connects to data-controller="tool-tip"
export default class extends Controller {
  static values = {
    info: String
  }

  initialize() {
    this.randomID = window.crypto.randomUUID();
    this.show = false;
  }

  showTip(e) {
    if (this.show) {
      this.hideTip();
      e.target.classList.remove('position-relative');
    } else {
      e.target.classList.add('position-relative')
      e.target.insertAdjacentHTML(
        'afterbegin',
        this.#tipHTML()
      )
    }
    this.show = !this.show;
  };

  hideTip() {
    const tip = document.getElementById(`${this.#tipID()}`);
    if (!tip) return;

    tip.remove();
  };

  // private

  #tipHTML() {
    const classes = 'position-absolute top--4 dark-gray-bg white-color light-border-rounded padding-1-4 full-width cursor-pointer';
    return (
      `<div class='${classes}' id='${this.#tipID()}' data-action='click->tool-tip#hideTip'>${this.infoValue}</div>`
    )
  }

  #tipID() {
    return `${this.randomID}-tip`;
  }
}
