import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static targets = ['time']

  connect() {
    flatpickr(this.timeTarget, {
      enableTime: true,
      altInput: true
    });
  }
}
