import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hang-man"

// The only logic that really needs to be in this JS contoller is the isValidPlay
// ... function. That validation benefits from being on client side - although
// ... could also be on the server, it's quicker if it's done on client side.
// ... The fetch function is not necessary and COULD be done with a button in the
// ... html.erb file.

export default class extends Controller {
  static values = {
    guessInformation: Object,
    guessUrl: String,
    alphabet: Array
  }
  connect() {
    window.addEventListener('keydown', this.boundGuessLetter);
  }

  disconnect() {
    window.removeEventListener('keydown', this.boundGuessLetter);
  }

  initialize() {
    this.boundGuessLetter = this.guessLetter.bind(this);
    this.isGameOver = false;
  }

  guessLetter(e) {
    const letter = e.key ? e.key.toUpperCase() : e.target.innerText;
    if (!this.isValidPlay(letter)) return;

    const params = this.credentialAndParams();
    fetch(this.guessUrlValue, {
      ...params,
      body: JSON.stringify({
        current_guess: letter,
        guess_information: this.guessInformationValue
      })
    }).then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html);
        console.info(this.guessInformationValue);
      })
      .catch(e => console.log(e));
  }

  credentialAndParams() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    return {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    }
  }

  isValidPlay(letter) {
    if (
      !this.alphabetValue.includes(letter) ||
      this.guessInformationValue.good_guesses.includes(letter) ||
      this.guessInformationValue.bad_guesses.includes(letter) ||
      this.guessInformationValue.is_game_lost ||
      this.guessInformationValue.is_word_guessed
    ) return false;
    return true;
  }
}
