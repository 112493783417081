import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spell-this"
export default class extends Controller {

  static values = {
    library: Array
  }

  static targets = [
    'spellword', 'timer', 'level', 'wordcount',
    'encouragement', 'counterAnswerContainer', 'goBtn',
    'submitScore', 'finalScore', 'userInput'
  ]

  initialize() {
    this.encouragement = [
      "Great - did you learn that at school?", "Nice one!!", "Hey, you can spell something!", "Not too bad after all...!",
      "Everyone is proud of you!", "Someone somewhere is happy about that", "You can do it!",
      "Good to see you can write", "You can spell", "Keep going!"
    ];
    this.correctWords = [];
    this.level = 0;
    this.isWinner = false;
  }

  connect() { }

  countDown() {
    this.timerTarget.innerText = 15000;
    let interval = setInterval(() => {
      if (this.timerTarget.innerText > 0) {
        this.timerTarget.innerText--
      } else {
        if (!this.isWinner) this.stopGameLooser();
        clearInterval(interval);
      }
    }, 10);
  }

  setNewWord(level) {
    this.levelTarget.innerText = level + 1;
    if (this.spellwordTarget.innerText !== "WORD") this.correctWords.push(this.spellwordTarget.innerText);
    let newWord = this.libraryValue[level];
    this.spellwordTarget.innerText = newWord;
  }

  randomNumber() {
    return Math.floor(Math.random() * 9);
  }

  userAnswer(e) {
    e.preventDefault()
    if (e.target.value.toLowerCase() === this.spellwordTarget.innerText.toLowerCase() && this.level < 15) {
      this.level++;
      this.setNewWord(this.level)
      this.setEncouragement(this.encouragement[this.randomNumber()])
      this.wordcountTarget.innerText = this.correctWords.length
      e.target.value = "";
    } else if (this.level === 15) {
      this.stopGameWinner()
    }
  }

  startGame() {
    this.goBtnTarget.style.display = 'none'
    this.counterAnswerContainerTarget.style.display = "flex"
    this.countDown();
    this.setNewWord(0);
    this.userInputTarget.focus();
  }

  setEncouragement(message) {
    this.encouragementTarget.innerText = `Computer says : ${message}`
  }

  stopGameLooser() {
    this.isWinner = false
    this.setEncouragement("oh dear...")
    this.spellwordTarget.innerHTML = "looser looser looser <br/> ------ <br/> Spell this : <br/> YOU LOOSE <br/> ------ <br/> looser looser looser"
    this.counterAnswerContainerTarget.style.display = "none"
  }

  stopGameWinner() {
    this.isWinner = true
    const score = Number(this.wordcountTarget.innerText) * Number(this.timerTarget.innerText);
    this.spellwordTarget.innerHTML = `winner winner winner <br/> ------ <br/> Spell this : <br/> YOU'RE AWESOME <br/> ------ <br/> final score : ${score} <br/> ------ <br/> winner winner winner`;
    this.counterAnswerContainerTarget.style.display = "none";
    this.submitScoreTarget.style.display = "block";
    this.finalScoreTarget.value = score;
  }
}
